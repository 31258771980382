
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import SearchCard from '@/components/cards/SearchCard.vue';
import NotFoundCard from '@/components/cards/NotFoundCard.vue';
import ImageCard from "@/components/cards/ImageCard.vue";

import { langs } from '@/constants';
import { Snack } from '@/entities/restaurant/Snack';
import { Location } from '@/entities/Location';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    SearchCard,
    NotFoundCard,
    ImageCard
  },

  computed: {
    ...mapGetters([
      'location'
    ])
  }
})
export default class SnacksListView extends Vue {
  location!: Location;
  snacks: Snack[] = [];

  search = "";

  langs = langs;
  lang = langs[0].abbr;

  mounted() {
    this.fetchSnacks();
  }

  get filtered() {
    if (this.search.trim() === "") return this.snacks;

    let filtered = this.snacks.filter((snack) => {
      const name = snack.name[this.lang] as string ?? "";
      return name.toLowerCase().includes(this.search.trim().toLowerCase());
    });

    return filtered;
  }
  
  snackName(snack: Snack) {
    return snack.name[this.lang];
  }

  @Watch('location')
  async fetchSnacks() {
    this.$snacks.getByLocation(this.location).then(snacks => {
      this.snacks = snacks;
    });
  }
}
